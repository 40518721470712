// ** Initial State
const initialState = {
  data: [],
  customer_me: {},
  filter_username: [],
  filter_bank: []
}

const customer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTER_CUSTOMER_BANK':
      return {
        ...state,
        filter_bank: action.data
      }
    case 'FILTER_CUSTOMER_USERNAME':
      return {
        ...state,
        filter_username: action.data
      }
    case 'CUSTOMER_GET_DATA':
      return {
        ...state,
        data: action.data
      }
    case 'CUSTOMER_ID_DATA':
      return {
        ...state,
        customer_me: action.data
      }
    case 'CLEAR_DATA_CUSTOMER':
      return {
        ...state,
        customer_me: action.data
      }
    default:
      return { ...state }
  }
}

export default customer