// ** Initial State
const initialState = {
    data: []
  }

  const credit = (state = initialState, action) => {
    switch (action.type) {
      case 'AGENT_GET_CREDIT':
        return {
          ...state,
          data: action.data
        }
      default:
        return { ...state }
    }
  }
  
  export default credit