const initialState = {
    lottery_data: [],
    lottery_round: [],
    lottery_type: []
  }
  
  const lottery = (state = initialState, action) => {
    switch (action.type) {
      case 'LOTTERY_GET_DATA':
        return {
          ...state,
          lottery_data: action.data
        }
      case 'LOTTERY_ROUND_GET_DATA':
        return {
          ...state,
          lottery_round: action.data
        }
      case 'LOTTERY_TYPE_GET_DATA':
        return {
          ...state,
          lottery_type: action.data
        }
      default:
        return { ...state }
    }
  }
  
  export default lottery