const initialState = {
    data: [],
    total: 1,
    params: {},
    refer: [],
    refer_all: [],
    dashboard: [],
    res: {
        open: false,
        code: 0,
        data: []
    }
}

export const marketing = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSFER_PARTNER_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'CUSTOMER_MARKETING_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'MARKETING_REFER_GET_DATA':
            return {
                ...state,
                refer: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'MARKETING_DASHBOARD_GET_DATA':
            return {
                ...state,
                dashboard: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'MARKETING_REFER_GET_DATA_All':
            return {
                ...state,
                refer_all: action.data,
                total: action.totalPages,
                params: action.params
            }

        case 'RESPONSE_ADD':
            return {
                ...state,
                res: {
                    open: action.open,
                    code: action.code,
                    data: action.data
                }
            }
        case 'CLEAR_RESPONSE':
            return {
                ...state,
                res: {
                    open: action.open,
                    code: action.code,
                    data: action.data
                }
            }
        default:
            return { ...state }
    }
}

