// ** Initial State
const initialState = {
    data: null,
    item: null,
    status: false,
    path: ''
}

const rolePermission = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROLE_PERMISSION_DATA':
            return {
                ...state,
                data: action.data
            }
        case 'SET_ROLE_PERMISSION_DATA':
            return {
                ...state,
                item: action.data
            }
        case 'SET_ROLE_PROCESSING':
            return {
                ...state,
                status: action.data
            }
        case 'SET_ROLE_PATH':
            return {
                ...state,
                path: action.data
            }
        default:
            return { ...state }
    }
}

export default rolePermission