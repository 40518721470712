// ** Initial State
const initialState = {
    holdList: [],
    total: 1,
    params: {},
    info: undefined,
    otp: null,
    menu: null
}

const accountHoldAll = (state = initialState, action) => {
    switch (action.type) {
        case 'BOT_ACCOUNT_HOLD_GET_ALL_DATA':
            return {
                ...state,
                holdList: action.data
            }
        default:
            return { ...state }
    }
}

const accountInfo = (state = initialState, action) => {
    switch (action.type) {
        case 'BOT_ACCOUNT_INFO_DATA':
            return {
                ...state,
                info: action.data
            }
        default:
            return { ...state }
    }
}

const accountOTP = (state = initialState, action) => {
    switch (action.type) {
        case 'BOT_ACCOUNT_OTP_DATA':
            return {
                ...state,
                otp: action.data
            }
        default:
            return { ...state }
    }
}

const depositMenu = (state = initialState, action) => {
    switch (action.type) {
        case 'DEPOSIT_MENU_ALL_DATA':
            return {
                ...state,
                menu: action.data
            }
        default:
            return { ...state }
    }
}

export {
    accountHoldAll,
    accountInfo,
    accountOTP,
    depositMenu
}
