// ** Initial State
const initialState = {
    data: {}
}

const transactionWait = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSACTION_WAIT_GET_DATA':
            return {
            ...state,
            data: action.data
            }
        default:
            return { ...state }
    }
}

export { transactionWait }