// ** Initial State
const initialState = {
    message: "",
    action: "",
    status: false
  }
  
  const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SNACKBAR_SHOW':
        return {
          ...state,
          message: action.message,
          action: action.action,
          status: true
        }
      case 'SNACKBAR_HIDE':
        return { ...state, status: false }
      default:
        return { ...state }
    }
  }
  
  export default snackbarReducer
  