// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  modal: {
    item: {},
    title: '',
    type: '',
    status: false
  },
  message: '',
  status: '',
  match_bank: [],
  search_match: {},
  data_success: null,
  data_withdraw_success: null
}

const paymentBanks = (state = initialState, action) => {
console.log("action: ", action)
  switch (action.type) {
    case 'PAYMENT_DEPOSIT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'PAYMENT_BANKS_ACCOUNT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'PAYMENT_BANKS_TRANSACTION_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'SAVE_DATA_APPROVE':
        return {
          ...state,
          data: action.data
        }
        case 'SAVE_DATA_APPROVE_WITHDRAW':
          return {
            ...state,
            data: action.data
          }
        case 'SAVE_DATA_APPROVE_SUCCESS':
          return {
            ...state,
            data_success: action.data
          }
          case 'SAVE_DATA_APPROVE_WITHDRAW_SUCCESS':
          return {
            ...state,
            data_withdraw_success: action.data
          }
          
    default:
      return { ...state }
  }
}

const agentBankListDeposit = (state = initialState, action) => {
  switch (action.type) {
    case 'AGENT_BANK_LIST_DEPOSIT':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

const paymentCheckDeposit = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_DEPOSIT':
      return {
        ...state,
        message: action.message,
        status: action.status
      }
    default:
      return { ...state }
  }
}

const promotionListDeposit = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_LIST_DEPOSIT':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

const withdrawInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'WITHDRAW_INFORMATION_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}

const customerListInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_LIST_INFORMATION_GET_DATA':
      // console.log("action: ", action)
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

const bankAgentListInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'BANK_AGENT_LIST_INFORMATION_GET_DATA':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

const modalDepositInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_DEPOSIT_INFORMATION_DATA':
      return {
        ...state,
        modal: action.data
      }
    default:
      return { ...state }
  }
}

const modalWithdrawInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_WITHDRAW_INFORMATION_DATA':
      return {
        ...state,
        modal: action.data
      }
    default:
      return { ...state }
  }
}

const searchUsernameData = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_USERNAME_DATA':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

const matchBankGetData = (state = initialState, action) => {
  switch (action.type) {
    case 'MATCH_BANK_GET_DATA':
      return {
        ...state,
        match_bank: action.data
      }
    case 'SEARCH_MATCH_BANK_GET_DATA':
      return {
        ...state,
        search_match: action.data
      }
    default:
      return { ...state }
  }
}

export { paymentBanks, agentBankListDeposit, promotionListDeposit, withdrawInformation, customerListInformation, bankAgentListInformation, modalWithdrawInformation, modalDepositInformation, searchUsernameData, paymentCheckDeposit, matchBankGetData }
