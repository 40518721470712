// ** Initial State
const initialState = {
    data: []
  }
  
const depositWait = (state = initialState, action) => {
    switch (action.type) {
      case 'DEPOSIT_WAIT_GET_DATA':
      return {
          ...state,
          data: action.data
        }
      default:
        return { ...state }
    }
  }
export default depositWait
// const transactionwait = (state = initialState, action) => {
//   switch (action.type) {
//     case 'TRANSACTION_WAIT_GET_DATA':
//     return {
//         ...state,
//         data: action.data
//       }
//     default:
//       return { ...state }
//   }
// }

// export { depositWait, transactionwait} 