// ** Initial State
const initialState = {
  datasound: true
}

const buttonSound = (state = initialState, action) => {
  switch (action.type) {
    case 'SOUND_ON':
      return {
        ...state,
        datasound: action.datasound
      }
    case 'SOUND_OFF':
      return {
        ...state,
        datasound: action.datasound
      }
    default:
      return { ...state }
  }
}

export default buttonSound