import initialState from './state'

const datatables = (state = initialState, action) => {
  switch (action.type) {
    case 'modal_customer_transaction_game':
      return {
        ...state,
        modal_customer_transaction_game: action.data
      }
    case 'modal_customer_transaction_game_winloss':
      return {
        ...state,
        modal_customer_transaction_game_winloss: action.data
      }
    case 'modal_customer_transaction_game_wheel':
      return {
        ...state,
        modal_customer_transaction_game_wheel: action.data
      }
    case 'modal_customer_transaction_game_ticket':
      return {
        ...state,
        modal_customer_transaction_game_ticket: action.data
      }
    case 'modal_customer_activity':
      return {
        ...state,
        modal_customer_activity: action.data
      }
    case 'modal_customer_transaction_credit':
      return {
        ...state,
        modal_customer_transaction_credit: action.data
      }
    case 'modal_customer_invite_friend':
      return {
        ...state,
        modal_customer_invite_friend: action.data
      }
    case 'modal_customer_promotion':
      return {
        ...state,
        modal_customer_promotion: action.data
      }
    case 'modal_customer_shop':
      return {
        ...state,
        modal_customer_shop: action.data
      }
    case 'modal_customer_wallets':
      return {
        ...state,
        modal_customer_wallets: action.data
      }
    case 'modal_customer_banks':
      return {
        ...state,
        modal_customer_banks: action.data
      }
    case 'organization_sub_history':
      return {
        ...state,
        organization_sub_history: action.data
      }
    // cleardata
    case 'clear_modal_customer_list':
      return {
        ...state,
        modal_customer_transaction_game: action.data,
        modal_customer_activity: action.data,
        modal_customer_transaction_credit: action.data,
        modal_customer_invite_friend: action.data,
        modal_customer_promotion: action.data,
        modal_customer_shop: action.data,
        modal_customer_wallets: action.data,
        modal_customer_banks: action.data
      }
    //payment
    case 'bank_bot_list':
      return {
        ...state,
        bank_bot_list: action.data
      }
    case 'deposit_list':
      return {
        ...state,
        deposit_list: action.data
      }
    case 'withdraw_list':
      return {
        ...state,
        withdraw_list: action.data
      }
    case 'organization_agentbank_transaction_list':
      return {
        ...state,
        organization_agentbank_transaction_list: action.data
      }
      case 'organization_agentbank_channel_list':
        return {
          ...state,
          organization_agentbank_channel_list: action.data
        }
      
    //customer
    case 'customer_list':
      return {
        ...state,
        customer_list: action.data
      }
    case 'organization_sub_list':
      return {
        ...state,
        organization_sub_list: action.data
      }
    //report
    case 'win_loss_list':
      return {
        ...state,
        win_loss_list: action.data
      }
    case 'win_loss_game_list':
      return {
        ...state,
        win_loss_game_list: action.data
      }
    case 'win_loss_customer_list':
      return {
        ...state,
        win_loss_customer_list: action.data
      }
    case 'commission_list':
      return {
        ...state,
        commission_list: action.data
      }
    case 'commission_category_list':
      return {
        ...state,
        commission_category_list: action.data
      }
    case 'cashback_list':
      return {
        ...state,
        cashback_list: action.data
      }
    case 'cashback_category_list':
      return {
        ...state,
        cashback_category_list: action.data
      }
    case 'game_cmap_list':
      return {
        ...state,
        game_cmap_list: action.data
      }
    case 'deposit_again_list':
      return {
        ...state,
        deposit_again_list: action.data
      }
    case 'customer_disappear':
      return {
        ...state,
        customer_disappear: action.data
      }
    case 'organization_agent_credit_transaction':
      return {
        ...state,
        organization_agent_credit_transaction: action.data
      }
    case 'marketing_report_referral':
      return {
        ...state,
        marketing_report_referral: action.data
      }
    case 'marketing_report_summary_referral':
      return {
        ...state,
        marketing_report_summary_referral: action.data
      }
    case 'report_mission_wheel':
      return {
        ...state,
        report_mission_wheel: action.data
      }
      case 'report_activity_apply':
        return {
          ...state,
          report_activity_apply: action.data
        }
      

    //setting
    case 'setting_role_list':
      return {
        ...state,
        setting_role_list: action.data
      }
    case 'game_list':
      return {
        ...state,
        game_list: action.data
      }
    case 'image_game_list':
      return {
        ...state,
        image_game_list: action.data
      }
    case 'banner_slide_list':
      return {
        ...state,
        banner_slide_list: action.data
      }
    case 'banner_slide_event_list':
      return {
        ...state,
        banner_slide_event_list: action.data
      }
    case 'banner_slide_hot_list':
      return {
        ...state,
        banner_slide_hot_list: action.data
      }
    case 'customer_referral_summary':
      return {
        ...state,
        customer_referral_summary: action.data
      }
    case 'setting_contact_list':
      return {
        ...state,
        setting_contact_list: action.data
      }
    case 'organization_agentbank_list':
      return {
        ...state,
        organization_agentbank_list: action.data
      }
    case 'setting_agent_list':
      return {
        ...state,
        setting_agent_list: action.data
      }
    case 'setting_agent_cms_list':
      return {
        ...state,
        setting_agent_cms_list: action.data
      }
    case 'mission_wheel_list':
      return {
        ...state,
        mission_wheel_list: action.data
      }
    case 'event_activity_list':
      return {
        ...state,
        event_activity_list: action.data
      }
    case 'event_activity_choose_list':
      return {
        ...state,
        event_activity_choose_list: action.data
      }
    case 'event_activity_reward_list':
      return {
        ...state,
        event_activity_reward_list: action.data
      }
      
    //promotion
    case 'promotion_apply_list':
      return {
        ...state,
        promotion_apply_list: action.data
      }

    //lottery 
    case 'lottery_ticket':
      return {
        ...state,
        lottery_ticket: action.data
      }
    case 'lottery_ticket_number':
      return {
        ...state,
        lottery_ticket_number: action.data
      }
    case 'lottery_round_result':
      return {
        ...state,
        lottery_round_result: action.data
      }
    case 'setting_category_withdraw_list':
      return {
        ...state,
        setting_category_withdraw_list: action.data
      }
      
    //marketing
    case 'transfer_customer':
      return {
        ...state,
        transfer_customer: action.data
      }
    case 'customer_referral_summary':
      return {
        ...state,
        customer_referral_summary: action.data
      }
    case 'marketing_referral_customer_list':
      return {
        ...state,
        marketing_referral_customer_list: action.data
      }
    case 'marketing_referral_member_list':
      return {
        ...state,
        marketing_referral_member_list: action.data
      }
    default:
      return { ...state }
  }

}

export default datatables
