const initialState = {
    tx: {
            data: null
    }
}
export const dataTx = (state = initialState, action) => {
    switch (action.type) {
      case 'TX_PATH_DATA':
        return {
          ...state,
          tx: action.data
        }
      default:
        return { ...state }
    }
  }